import React, { Component } from 'react'
import { Link } from 'gatsby'
import { getCookie, setCookie } from '../utils/cookie'

import { Logo, Search, Close, LoadMore } from './icons.js'

class Header extends Component {

  state = {
    offCanvas: false,
    search: false,
    hide: false,
    policy: false,
    latestNews: false,
    timing: 0,
  }

  componentDidMount() {
    this.setHeight()
    window.addEventListener('scroll', this._onScroll)
    window.addEventListener('resize', this.setHeight)
    document.querySelector('body').classList.remove('footer-showing')
    this.setState({ policy: getCookie('policy') })
    this.setState({ latestNews: getCookie('latest_news') })
    let { timing } = this.state
    this.interval = setInterval(() => {
      this.setState({ timing: ++timing })
      if (timing === 3) clearInterval(this.interval)
    }, 1000)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._onScroll)
    window.removeEventListener('resize', this.setHeight)
    clearInterval(this.interval)
    clearTimeout(this.timeout)
  }

  _hidePopup = () => {
    setCookie('policy', true);
    this.setState({ policy: true })
  }
  _hideLatestNews = () => {
    setCookie('latest_news', true);
    this.setState({ latestNews: true })
  }

  _onScroll = () => {

    let x = document.querySelector('main').offsetHeight

    if ((window.scrollY + 200) >= x) {
      this.setState({ hide: true })
    } else {
      this.setState({ hide: false })
    }
    if (window.scrollY + window.innerHeight + 2 >= x) {
      setTimeout(() => {
        if (window.scrollY + window.innerHeight + 2 >= x) {
          document.querySelector('body').classList.add('footer-showing')
        }
      }, 800);
      return
    }
    if ( document.querySelector('body').classList.contains('footer-showing') ) {
      setTimeout(() => {
        if ((window.scrollY + window.innerHeight) <= x) {
          document.querySelector('body').classList.remove('footer-showing')
        }
      }, 800);
    }

  }

  setHeight = () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  _toggleOffCanvas = () => {
    this.setState({
      offCanvas: !this.state.offCanvas,
      search: false
    })
  }

  _toggleSearch = () => {
    this.setState({
      search: !this.state.search,
      offCanvas: false
    })
  }

  _scrollTo = (element) => {
    document.querySelector(element).scrollIntoView({
      behavior: 'smooth'
    })
  }

  render() {
    let { offCanvas, search, hide, policy, latestNews, timing } = this.state
    let { data } = this.props
    let post = data.allWordpressPost.edges[0].node
    let props = {
      onClick: this._toggleOffCanvas,
      activeClassName: 'active'
    }
    let headerClass = `header ${hide ? 'header--hide' : ''}`
    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <div className='header__logo'>
              <Link to='/'><Logo colour={'#000'} /></Link>
              <span onClick={() => this._scrollTo('body')}>EM Advisory</span>
            </div>
            <div className='header__menu'>
              <nav className='header__nav'>
                <ul>
                  <li onClick={this._toggleSearch}><Search colour={'#1D1D1B'} /><span>Search</span></li>
                  <li onClick={this._toggleOffCanvas}>Menu</li>
                </ul>
              </nav>
            </div>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <button className='off-canvas__close' onClick={this._toggleOffCanvas}>
            <Close colour={'#FFFFFF'} />
          </button>
          <nav className='off-canvas__nav'>
            <ul>
              <li><Link to='/our-offering' {...props}>Our Offering</Link></li>
              <li><Link to='/approach' {...props}>Approach</Link></li>
              <li><Link to='/people' {...props}>People</Link></li>
              <li><Link to='/clients' {...props}>Clients</Link></li>
              <li><Link to='/contact' {...props}>Contact</Link></li>
            </ul>
          </nav>
        </div>
        <div className={`search-screen ${ search && 'search-screen--active' }`}>
          <button className='search-screen__close' onClick={this._toggleSearch}>
            <Close colour={'#FFFFFF'} />
          </button>
          <form className='search-screen__form' action='/search'>
            <input type='text' name='search' placeholder='Search...' />
            <button type='submit'>
              <LoadMore colour={'#FFF'} />
            </button>
          </form>
        </div>
        { !policy &&
          <div className="cookie">
            <p>EM Advisory uses cookies. <Link to='/privacy'>Read More</Link></p>
            <span onClick={this._hidePopup}><Close colour={'#000000'} /></span>
          </div>
        }
        { !latestNews &&
          <div className={`latest-post ${policy ? 'no-policy': ''} ${timing > 0.5 ? 'fade-in' : ''}`}>
            {/*<p><span className="latest-post__blinker"></span> <Link className='no-line' to='/journal'>EMA Journal</Link></p>*/}
            <p>{post.title} <Link to={`/article/${post.slug}`}>Read More</Link></p>
            <span onClick={this._hideLatestNews}><Close colour={'#FFFFFF'} /></span>
          </div>
        }
      </>
    )
  }
}

export default Header
