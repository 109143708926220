import React, { Component } from 'react'
import { Link } from 'gatsby'

import FooterLogo from '../assets/images/footer-logo.svg'

class Footer extends Component {

  _scrollTo = (element) => {
    document.querySelector(element).scrollIntoView({
      behavior: 'smooth'
    })
  }

  render() {
    return (
      <>
        <section className='footer-dummy'></section>
        <footer className='footer'>
          <div className='footer__inner'>
            <div className='footer__tagline'>
              <p>A deal is so much more than a transaction. At EM Advisory, we specialise in making the right deals, the right way and for the right reasons.</p>
            </div>
            <div className='footer__logo'>
              <Link to='/'>
                <img src={FooterLogo} alt='Em Advisory' />
              </Link>
            </div>
            <nav className='footer__nav'>
              <ul>
                <li><Link to='/'>EM Advisory</Link><br />Level 7, 50 Market Street<br />Melbourne, Australia, VIC 3000<br />AFSL 419715</li>
                <li><a href='tel:+61384889927'>+61 3 8488 9927</a><br /><a href='mailto:hello@emadvisory.com'>hello@emadvisory.com</a></li>
                <li className='footer__nav-social'><a href='https://au.linkedin.com/company/em-advisory' target='_blank' rel='noopener noreferrer'>LinkedIn</a><br /><a href='https://twitter.com/em_advisory' target='_blank' rel='noopener noreferrer'>Twitter</a></li>
                <li className='footer__nav-legal'>
                  <Link to='/privacy'>Privacy</Link>
                </li>
                <li className='footer__nav-last'>
                  <div className='footer__credits'>
                    <a href='https://y-m-d.com.au' target='_blank' rel='noopener noreferrer'>Design YMD</a><br /><a href='https://codedrips.com' target='_blank' rel='noopener noreferrer'>Build CodeDrips</a>
                  </div>
                  <div className='footer__top'>
                    <span onClick={() => this._scrollTo('body')}>Back to Top</span>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </footer>
      </>
    )
  }
}

export default Footer
