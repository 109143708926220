import React, { Component } from 'react'

class Logo extends Component {
  render() {
    let { colour } = this.props
    return (
      <svg width="59px" height="30px" viewBox="0 0 59 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={colour}>
            <g>
              <polygon points="0 0.0533333333 0 14.1578614 23.1521955 14.1578614 23.1521955 14.9490996 0 14.9490996 0 29.0533333 29 29.0533333 29 22.4064023 5.73859599 22.4064023 5.73859599 21.6145753 29 21.6145753 29 7.49179698 5.8475101 7.49179698 5.8475101 6.70055874 29 6.70055874 29 0.0533333333"></polygon>
              <polygon points="44.8956231 0.0533333333 44.8956231 23.2058232 44.1040825 23.2058232 44.1040825 0.0533333333 30 0.0533333333 30 29.0533333 36.6469985 29.0533333 36.6469985 6.69356151 37.4385392 6.69356151 37.4385392 29.0533333 51.5614608 29.0533333 51.5614608 6.80247562 52.3530015 6.80247562 52.3530015 29.0533333 59 29.0533333 59 0.0533333333"></polygon>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class Close extends Component {
  render() {
    let { colour } = this.props
    return (
      <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
          <g transform="translate(-1386.000000, -28.000000)" stroke={colour} strokeWidth="1.5">
            <g transform="translate(1387.000000, 29.000000)">
              <path d="M0.666666667,0.666666667 L19.9947917,19.9947917"></path>
              <path d="M0.00520833333,0.666666667 L19.3333333,19.9947917" transform="translate(9.669271, 10.330729) rotate(-270.000000) translate(-9.669271, -10.330729)"></path>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class Search extends Component {
  render() {
    let { colour } = this.props
    return (
      <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-287.000000, -13.000000)" fill={colour} fillRule="nonzero">
            <g transform="translate(287.000000, 13.000000)">
              <path d="M13.8696891,11.9898686 L11.4283543,9.54845655 C12.0471095,8.58306158 12.4114481,7.43770777 12.4114481,6.20547728 C12.4114481,2.77828083 9.63325524,0 6.20616729,0 C2.77819287,0 0,2.77828083 0,6.20547728 C0,9.63267374 2.77819287,12.4109546 6.20528082,12.4109546 C7.4374723,12.4109546 8.58190337,12.0474909 9.54726778,11.4287162 L11.989489,13.8710147 C12.1605775,14.0429951 12.443361,14.0429951 12.6162224,13.8710147 L13.8696891,12.6175083 C14.043437,12.4437549 14.043437,12.161849 13.8696891,11.9898686 M6.20528082,10.6379611 C3.76128665,10.6379611 1.77293738,8.64954884 1.77293738,6.20547728 C1.77293738,3.76140573 3.76128665,1.77299351 6.20528082,1.77299351 C8.649275,1.77299351 10.6376243,3.76140573 10.6376243,6.20547728 C10.6376243,8.64954884 8.65016146,10.6379611 6.20528082,10.6379611"></path>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class Plus extends Component {
  render() {
    let { colour } = this.props
    return (
      <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(0.000000, -11.000000)">
            <g transform="translate(0.000000, 11.000000)">
              <circle fill={colour} cx="7" cy="7" r="7"></circle>
              <polygon fill="#FFFFFF" fillRule="nonzero" points="11 7.61590525 7.7943761 7.61590525 7.7943761 11 6.2056239 11 6.2056239 7.61590525 3 7.61590525 3 6.16751269 6.2056239 6.16751269 6.2056239 3 7.7943761 3 7.7943761 6.16751269 11 6.16751269"></polygon>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class Minus extends Component {
  render() {
    let { colour } = this.props
    return (
      <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(0.000000, -10.000000)">
            <g transform="translate(0.000000, 10.000000)">
              <circle fill={colour} cx="7" cy="7" r="7"></circle>
              <polygon fill="#FFFFFF" fillRule="nonzero" points="11 7.61590525 7.7943761 7.61590525 6.2056239 7.61590525 3 7.61590525 3 6.16751269 6.2056239 6.16751269 7.7943761 6.16751269 11 6.16751269"></polygon>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class LoadMore extends Component {
  render() {
    let { colour } = this.props
    return (
      <svg width="14px" height="16px" viewBox="0 0 14 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-713.000000, -2392.000000)" fill={colour} fillRule="nonzero">
            <g transform="translate(614.000000, 2354.000000)">
              <polygon transform="translate(105.900000, 46.360000) rotate(-90.000000) translate(-105.900000, -46.360000)" points="113.27 47.08 101.19 47.08 105.89 51.72 104.93 52.72 98.53 46.36 104.93 40 105.89 41 101.19 45.64 113.27 45.64"></polygon>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class DownArrow extends Component {
  static defaultProps = {
    colour: '#000000',
  }

  render() {
    let { colour } = this.props

    return (
      <svg width="14px" height="16px" viewBox="0 0 14 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-713.000000, -2391.000000)" fill={colour} fillRule="nonzero">
            <g transform="translate(614.000000, 2353.000000)">
              <polygon transform="translate(105.900000, 46.360000) rotate(-90.000000) translate(-105.900000, -46.360000) " points="113.27 47.08 101.19 47.08 105.89 51.72 104.93 52.72 98.53 46.36 104.93 40 105.89 41 101.19 45.64 113.27 45.64"></polygon>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class SearchArrow extends Component {
  render() {
    let { colour } = this.props
    return (
      <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-287.000000, -13.000000)" fill={colour} fillRule="nonzero">
              <g transform="translate(287.000000, 13.000000)">
                <path d="M13.8696891,11.9898686 L11.4283543,9.54845655 C12.0471095,8.58306158 12.4114481,7.43770777 12.4114481,6.20547728 C12.4114481,2.77828083 9.63325524,0 6.20616729,0 C2.77819287,0 0,2.77828083 0,6.20547728 C0,9.63267374 2.77819287,12.4109546 6.20528082,12.4109546 C7.4374723,12.4109546 8.58190337,12.0474909 9.54726778,11.4287162 L11.989489,13.8710147 C12.1605775,14.0429951 12.443361,14.0429951 12.6162224,13.8710147 L13.8696891,12.6175083 C14.043437,12.4437549 14.043437,12.161849 13.8696891,11.9898686 M6.20528082,10.6379611 C3.76128665,10.6379611 1.77293738,8.64954884 1.77293738,6.20547728 C1.77293738,3.76140573 3.76128665,1.77299351 6.20528082,1.77299351 C8.649275,1.77299351 10.6376243,3.76140573 10.6376243,6.20547728 C10.6376243,8.64954884 8.65016146,10.6379611 6.20528082,10.6379611"></path>
              </g>
            </g>
        </g>
      </svg>
    )
  }
}

export {
  Logo, Close, Search, Plus, Minus, LoadMore, SearchArrow, DownArrow
}
