import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../components/header'
import Footer from '../components/footer'
import Transition from '../components/transition'

import '../assets/scss/main.scss'

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      fragment original on wordpress__wp_media {
        alt_text
        localFile {
          childImageSharp {
            original {
              src
              width
              height
            }
          }
        }
      },
      {
        site {
          siteMetadata {
            title
          }
        }
        allWordpressPost(filter: { slug: { ne: "test-post"} }) {
          edges {
            node {
              title
              slug
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <>
            <Header location={location} data={data} />
            <Transition location = {location}>
              <main>
                { children }
              </main>
              <Footer data={data} />
            </Transition>
        </>
      )
    }
    }
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
